import '../scss/app.scss'

import openMenu from './modules/menu'
import { closeMenu } from './modules/menu'
import forms from './modules/form';
import smoothAnchors from './modules/anchors'

window.addEventListener('DOMContentLoaded', () => {
    openMenu('.hamburger', '.header')
    closeMenu('.header__close', '.header')
    forms('form')

    smoothAnchors()
})
