const openMenu = (triggerSelector, menuSelector) => {
  const menu = document.querySelector(menuSelector);
  const trigger = document.querySelector(triggerSelector);

  trigger.addEventListener("click", () => {
    menu.classList.add("active");
  });
};

const closeMenu = (closeSeletor, menuSelector) => {
  const menu = document.querySelector(menuSelector);
  const close = document.querySelector(closeSeletor);

  close.addEventListener("click", () => {
    menu.classList.remove("active");
  });

  menu.addEventListener("click", (e) => {
    if (e.target === menu) {
      menu.classList.remove("active");
    }
  });
};

export default openMenu;
export { closeMenu };
