import axios from 'axios'

function forms(formSelector) {
	const forms = document.querySelectorAll(formSelector)

	const message = {
		success: 'Спасибо! Скоро мы с вами свяжемся',
		failure: 'Что-то пошло не так...',
	}

	forms.forEach(item => {
		bindPostData(item)
	})

	function bindPostData(form) {
		form.addEventListener('submit', e => {
			e.preventDefault()

			const formData = new FormData(form)

			axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
			axios.defaults.xsrfCookieName = "csrftoken"

			axios.post('/content/contact/', formData)
			.then(res => {
				showThanksModal(message.success)
			})
			.catch(() => {
				showThanksModal(message.failure)
			})
			.finally(() => {
				form.reset()
			})
		})
	}

	function showThanksModal(message) {
		const thanksModal = document.createElement('div')
		thanksModal.innerHTML = `
            <div>
                <div class="title_fz44 title_fz44--modal">${message}</div>
            </div>
        `

		thanksModal.style.cssText = `
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 700px;
			height: 200px;
			background-color: #232834;
			position: fixed;
			z-index: 9999;
		`
		document.querySelector('body').append(thanksModal)

		setTimeout(() => {
			thanksModal.remove()
		}, 2000)
	}
}

export default forms
